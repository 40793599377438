<template>
    <div class="serveDetail">
        <el-card>
            <el-form :inline="true" :model="searchForm">
                <el-form-item label="选择时间">
                    <el-date-picker
                        v-model="timeRange"
                        value-format="yyyy-MM-dd"
                        clearable
                        @change="dateTimeChange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="所属组织">
                    <el-select class="w350" v-model="searchForm.streetId" placeholder="请选择" filterable clearable>
                        <el-option
                            v-for="item in streetList"
                            :label="item.streetName"
                            :value="item.streetId"
                            :key="item.streetId"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="服务机构">
                    <el-select class="w350" v-model="searchForm.serviceId" placeholder="请选择" filterable clearable>
                        <el-option v-for="item in organList" :label="item.serviceName" :value="item.id" :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="服务项目">
                    <el-cascader
                        ref="comboCascader"
                        v-model="serPackData"
                        :options="comboOptions"
                        :props="{ value: 'id' }"
                        class="w350"
                        clearable
                        placeholder="请选择服务项目"
                        @change="changeService"
                    />
                </el-form-item>
                <el-form-item label="服务类型">
                    <el-select class="w350" v-model="searchForm.serviceType" placeholder="请选择" clearable>
                        <el-option v-for="item in SERVICETYPEOPTIONS" :label="item.label" :value="item.value" :key="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="订单状态">
                    <el-select class="w350" v-model="searchForm.status" placeholder="请选择" clearable>
                        <el-option v-for="item in STATUSOPTIONS" :label="item.label" :value="item.value" :key="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="异常状态">
                    <el-select class="w350" v-model="searchForm.exception" placeholder="请选择" clearable>
                        <el-option v-for="item in EXCEPTIONOPTIONS" :label="item.label" :value="item.value" :key="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="搜索内容">
                    <el-input class="w350" v-model="searchForm.keyWord" placeholder="请输入姓名/手机号" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="doSearch">搜索</el-button>
                </el-form-item>
            </el-form>
            <el-table style="width: 100%" :data="tableData" border :header-cell-style="headerCellStyle" v-loading="loading">
                <template slot="empty">
                    <IsEmpty />
                </template>
                <el-table-column type="index" label="序号" width="50" align="center" />
                <el-table-column prop="orderId" label="订单编号" align="center" width="180">
                    <template slot-scope="scope">
                        <span
                            :style="{ color: scope.row.preServiceException === 1 || scope.row.aftServiceException === 1 ? '#f02929' : '' }"
                        >
                            {{ scope.row.orderId }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="buyerName" label="预约人姓名" align="center" width="100" />
                <el-table-column prop="buyerPhone" label="预约人手机号" align="center" width="120" />
                <el-table-column prop="street" label="所属组织" align="center" width="200" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <span>{{ scope.row.tenantName ? `${scope.row.tenantName}/${scope.row.organizationName}` : '--' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="elderlyAbility" label="老人能力评估" align="center" width="120">
                    <template slot-scope="scope">
                        {{ getContent(ELDERLYABILITY, scope.row.elderlyAbility, 'label', 'text') }}
                    </template>
                </el-table-column>
                <el-table-column prop="elderlyType" label="老人类型" align="center" width="160" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        {{ getContent(ELDERLYTYPE, scope.row.elderlyType, 'label', 'text') }}
                    </template>
                </el-table-column>
                <el-table-column prop="comboName" label="预定套餐" align="center" width="180" :show-overflow-tooltip="true" />
                <el-table-column prop="cost" label="费用标准" align="center" width="100" />
                <el-table-column prop="quantity" label="数量" align="center" width="100" />
                <el-table-column prop="payment" label="总金额(元)" align="center" width="100" />
                <el-table-column prop="times" label="服务时长" align="center" width="100" />
                <el-table-column prop="createTime" label="下单时间" align="center" width="160" />
                <el-table-column prop="apptTime" label="预约时间" align="center" width="100" />
                <el-table-column prop="serviceName" label="服务机构" width="160" :show-overflow-tooltip="true" />
                <el-table-column prop="address6" label="操作" min-width="150" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" @click="seeDetail(scope.row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tableParams.pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableParams.pageSize"
                layout="total, sizes, prev, pager, next"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <!-- 订单详情 -->
        <OrderDetail :drawerShow.sync="drawerShow" :orderId="orderId" :orderInfo="orderInfo" />
    </div>
</template>

<script>
import OrderDetail from './components/orderDetail.vue'
import { getContent } from '@/utils/index'
import { ELDERLYABILITY, ELDERLYTYPE, STATUSOPTIONS, SERVICETYPEOPTIONS, EXCEPTIONOPTIONS } from '@/data/dataDictionary'
import { streetList, serviceNameList, backPage, backCascader2 } from '@/api/orderManage'
export default {
    name: 'serveDetail',
    components: {
        OrderDetail
    },
    data() {
        return {
            ELDERLYABILITY,
            ELDERLYTYPE,
            STATUSOPTIONS,
            SERVICETYPEOPTIONS,
            EXCEPTIONOPTIONS,
            loading: false,
            drawerShow: false,
            timeRange: '', // 时间范围
            serPackData: [], // 服务项目
            comboOptions: [], // 服务项目options
            searchForm: {
                bedStatus: 1, // 显示绑定床位的订单
                staTime: '', // 开始时间
                endTime: '', // 结束时间
                streetId: null, // 街道id
                serviceId: null, // 服务机构id
                serPackId: null, // 服务项目id
                serviceType: null, // 服务类型
                status: null, // 订单状态
                exception: null, // 异常状态
                keyWord: '' // 搜索内容
            },
            streetList: [], // 街道列表
            organList: [], // 机构列表
            tableData: [],
            tableParams: {
                pageSize: 10,
                pageNum: 1
            },
            total: 10,
            orderId: null, // 订单ID
            orderInfo: {} // 订单信息
        }
    },
    watch: {
        $route: {
            handler(val) {
                if (val.query.orderId) {
                    this.searchForm.keyWord = val.query.orderId
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.getStreetList()
        this.getOrganList()
        this.getServiceList()
        this.getList()
    },
    methods: {
        getContent,
        // 获取街道列表
        getStreetList() {
            streetList().then(res => {
                this.streetList = res.data
            })
        },
        // 获取机构列表
        getOrganList() {
            serviceNameList().then(res => {
                this.organList = res.data
            })
        },
        // 获取服务列表
        getServiceList() {
            backCascader2().then(res => {
                this.comboOptions = res.data
            })
        },
        // 更改时间
        dateTimeChange(time) {
            if (time) {
                this.searchForm.staTime = time[0]
                this.searchForm.endTime = time[1]
            } else {
                this.searchForm.staTime = ''
                this.searchForm.endTime = ''
            }
        },
        // 选择服务项目
        changeService(value) {
            if (value.length > 0) {
                this.searchForm.serPackId = value[value.length - 1]
            } else {
                this.searchForm.serPackId = ''
            }
        },
        handleSizeChange(pageSize) {
            this.tableParams.pageSize = pageSize
            this.doSearch()
        },
        handleCurrentChange(pageNum) {
            this.tableParams.pageNum = pageNum
            this.getList()
        },
        doSearch() {
            this.tableParams.pageNum = 1
            this.getList()
        },
        getList() {
            this.loading = true
            backPage({ ...this.searchForm, ...this.tableParams })
                .then(res => {
                    this.tableData = res.data.rows
                    this.total = res.data.total
                    this.loading = false
                })
                .catch(() => {
                    this.tableData = []
                    this.total = 0
                    this.loading = false
                })
        },
        seeDetail(row) {
            this.orderId = row.orderId
            this.orderInfo = row
            this.drawerShow = true
        }
    }
}
</script>

<style lang="scss" scoped>
.serveDetail {
    .w350 {
        width: 350px;
    }
}
</style>
